export default {
  'Deutsch': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/de_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'English': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/en_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': [
      {
        'preview': new URL('../assets/posters/en_17x11inch_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_17x11inch_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_17x11inch_six_safety_commitments.pdf', import.meta.url),
      },
    ],
  },
  'Français': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/fr_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Português': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/pt_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Español': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/es_eu_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_eu_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_eu_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Español (Latam)': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/es_latam_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_latam_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_latam_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Italiano': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/it_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  '日本語': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/jp_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  '한국어': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/ko_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ko_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ko_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'عرب': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/ar_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/ar_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/ar_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Suomalainen': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/fi_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fi_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fi_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Български': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/bgr_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/bgr_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/bgr_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Nederlands': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/nl_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/nl_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/nl_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
  'Kiswahili': {
    'DIN A3': [
      {
        'preview': new URL('../assets/posters/swa_420x297mm_six_safety_commitments.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/swa_420x297mm_six_safety_commitments.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/swa_420x297mm_six_safety_commitments.pdf', import.meta.url),
      },
    ],
    'US Tabloid': null,
  },
};
